export default function Footer() {
  return (
    <footer className="footer footer-transparent d-print-none pt-2">
      <div className="container-xl">
        <div className="row text-center align-items-center flex-row-reverse">
          {/* <div className="col-lg-auto ms-lg-auto">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">
                <a href="./license.html" className="link-secondary">
                  License
                </a>
              </li>
            </ul>
          </div> */}
          <div className="col-12 col-lg-auto2 mt-3 mt-lg-0">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item fs-5">
                Copyright &copy; 2023 Shopiget | Tüm hakları saklıdır.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
