import axios from "../utils/CustomAxios";

const TaxAPI = {
  create: (data) => {
    return axios.post("v1/panel/taxes", data);
  },
  get: (id) => {
    return axios.get("v1/panel/taxes/" + id);
  },
  getAll: (data = {}) => {

    const {
      page = null,
      limit = null,
      order_index = null,
      order_direction = null,
      ...filters
    } = data.params || {};


    return axios.get("v1/panel/taxes", {
      params: {
        page: page ? page - 1 : 0,
        limit: limit || 100,
        order_index: order_index || "taxes.id",
        order_direction: order_direction || "desc",
        ...filters,
      },
    });
  },
  delete: (id) => {
    return axios.delete("v1/panel/taxes/" + id);
  },
  update: (id, data) => {
    return axios.put("v1/panel/taxes/" + id, data);
  },
};

export default TaxAPI;
