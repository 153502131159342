import { AlertTriangleIcon, CircleIcon } from "./Icons";

export function Modal({
  title = "",
  visible,
  setVisible,
  style = {},
  className = "",
  onClose = () => {},
  children,
  modalStyle = {},
  headerStyle = {},
  bodyStyle = {},
}) {
  if (!visible) return null;

  return (
    <div
      className={"modal modal-blur fade " + (visible ? "show" : "")}
      style={{ display: visible ? "block" : "none" }}
    >
      <div
        style={{ ...modalStyle }}
        className={"modal-dialog modal-lg modal-dialog-centered " + className}
      >
        <div style={{ ...style }} className="modal-content shadow-lg ">
          <div style={{ ...headerStyle }} className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                onClose();
                setVisible(false);
              }}
            ></button>
          </div>
          <div style={{ ...bodyStyle }} className="modal-body">
            {children}
          </div>
          {/*
        
         <div className="modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Client name</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Reporting period</label>
                  <input type="date" className="form-control" />
                </div>
              </div>
              <div className="col-lg-12">
                <div>
                  <label className="form-label">Additional information</label>
                  <textarea className="form-control" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        */}
          {/*
      <div className="modal-footer">
            <a
              href="#"
              className="btn btn-link link-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </a>
            <a
              href="#"
              className="btn btn-primary ms-auto"
              data-bs-dismiss="modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Create new report
            </a>
          </div>
      */}
        </div>
      </div>
    </div>
  );
}

// export function SuccessModal({
//   visible,
//   setVisible,
//   title,
//   description,
//   leftButtonText = "?",
//   leftButtonOnClick = () => {},
//   rightButtonText = "?",
//   rightButtonOnClick = () => {},
//   type,
//   icon,
// }) {
//   return (
//     <div
//       className={"modal modal-blur fade " + (visible ? "show" : "")}
//       style={{ display: "block" }}
//     >
//       <div className="modal-dialog modal-sm modal-dialog-centered">
//         <div className="modal-content">
//           <button
//             type="button"
//             className="btn-close"
//             onClick={() => setVisible(false)}
//           ></button>
//           <div className={"modal-status bg-" + type}></div>
//           <div className="modal-body text-center py-4">
//             {icon}
//             <h3>{title}</h3>
//             <div className="text-muted">{description}</div>
//           </div>
//           <div className="modal-footer">
//             <div className="w-100">
//               <div className="row">
//                 <div className="col">
//                   <span className="btn w-100" onClick={leftButtonOnClick}>
//                     {leftButtonText}
//                   </span>
//                 </div>
//                 <div className="col">
//                   <span
//                     className={"btn w-100 btn-" + type}
//                     onClick={rightButtonOnClick}
//                   >
//                     {rightButtonText}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

export function SuccessModal({
  visible,
  setVisible,
  title,
  description,
  leftButtonText = "?",
  leftButtonOnClick = () => {},
  rightButtonText = "?",
  rightButtonOnClick = () => {},
}) {
  return (
    <div
      className={"modal modal-blur fade " + (visible ? "show" : "")}
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content shadow-lg">
          <button
            type="button"
            className="btn-close"
            onClick={() => setVisible(false)}
          ></button>
          <div className="modal-status bg-green"></div>
          <div className="modal-body text-center py-4">
            <CircleIcon className="mb-2 icon-lg text-green" />
            <h3>{title}</h3>
            <div className="text-muted">{description}</div>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <div className="row">
                <div className="col">
                  <span className="btn w-100" onClick={leftButtonOnClick}>
                    {leftButtonText}
                  </span>
                </div>
                <div className="col">
                  <span
                    className="btn w-100 btn-green"
                    onClick={rightButtonOnClick}
                  >
                    {rightButtonText}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DangerModal({
  visible,
  setVisible,
  title,
  description,
  leftButtonText = "?",
  leftButtonOnClick = () => {},
  rightButtonText = "?",
  rightButtonOnClick = () => {},
}) {
  return (
    <div
      className={"modal modal-blur fade " + (visible ? "show" : "")}
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content shadow-lg">
          <button
            type="button"
            className="btn-close"
            onClick={() => setVisible(false)}
          ></button>
          <div className="modal-status bg-danger"></div>
          <div className="modal-body text-center py-4">
            <AlertTriangleIcon className="mb-2 icon-lg text-danger" />
            <h3>{title}</h3>
            <div className="text-muted">{description}</div>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <div className="row">
                <div className="col">
                  <span className="btn w-100" onClick={leftButtonOnClick}>
                    {leftButtonText}
                  </span>
                </div>
                <div className="col">
                  <span
                    className="btn w-100 btn-danger"
                    onClick={rightButtonOnClick}
                  >
                    {rightButtonText}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
