import axios from "axios";
import { store } from "../redux/store";
import { addMessagesQueue } from "../redux/slices/messageSlice";
import { setUserData } from "../redux/slices/userSlice";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_LINK,
  withCredentials: false,
});

instance.interceptors.request.use(async function (config) {
  config.headers["Authorization"] =
    "Bearer " + localStorage.getItem("token") || "";

  config.headers["x-sid"] = localStorage.getItem("shop_id") || 1; //shop id
  config.headers["x-lid"] = localStorage.getItem("lang_id") || 175; //language id

  return config;
});

instance.interceptors.response.use(
  function (response) {
    if (response && response.data && response.data.messages !== undefined) {
      // OSMAN alttakini iptal ettim şimdilik
      //store.dispatch(addMessagesQueue(response.data.messages));
    }

    return response;
  },
  function (error) {
    //token hatası alırsa giriş sayfasına yönlendirme
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("shop_id");
      store.dispatch(
        setUserData({
          isLoad: false,
          isLogin: false,
          data: {
            permissions: [],
          },
        })
      );
    }

    //gelen hatada messages verisi varsa ekrana bas
    if (error.response?.data?.messages !== undefined) {
      // OSMAN alttakini iptal ettim şimdilik
      // store.dispatch(addMessagesQueue(error.response.data.messages));
    }

    return Promise.reject(error);
  }
);

export default instance;
