import { useEffect, useState } from "react";

export default function Tabs({
  tabs,
  children,
  onChange = (index) => {},
  changeTabActive = false,
  outsideTabIndex = -1,
  isTabClickable = true,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const changeActiveIndex = (index) => {
    setActiveIndex(index);
    onChange(index);
  };

  const changeTabFromOutside = (index) => {
    if (changeTabActive) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (outsideTabIndex === -1) return;
    changeTabFromOutside(outsideTabIndex);
  }, [outsideTabIndex]);

  return (
    <div className="card">
      <div className="card-status-top bg-blue"></div>
      <div className="card-header">
        <ul className="nav nav-pills card-header-pills">
          {tabs.map((item, key) => {
            return (
              <li
                key={key}
                className={`nav-item ${isTabClickable && "cursor-pointer"}`}
                onClick={() => {
                  isTabClickable && changeActiveIndex(key);
                }}
              >
                <span
                  className={
                    "nav-link " + (key === activeIndex ? "active" : "")
                  }
                >
                  {item.icon || null}
                  {item.name}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          {Array.isArray(children)
            ? children.map((child, key) => (key === activeIndex ? child : null))
            : children}
        </div>
      </div>
    </div>
  );
}

export function Tab({ children }) {
  return (
    <div className="tab-pane fade active show">
      <div>{children}</div>
    </div>
  );
}
