import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languages: [],
  selectedLanguageCode: null, // Turkish: 175, English: 56
  pageBuilderOffCanvas: { visible: false, type: 0 }, //1:komponent ekleme, 2: item düzenleme, 3: component edit
  selectedPageBuilderItemIndex: 0,
  selectedPageBuilderItemDataIndex: 0,
  pageBuilderItems: [],
  themes: [],
  selectedTheme: {},
  i_frame: true,
  preview: {
    key: 0,
    url: process.env.REACT_APP_SHOPIGET_URI,
    width: "100%",
  },
  replace_data: {},
  replace_index: 0,
};

// console.log(process.env.REACT_APP_SHOPIGET_URI)
// `${preview.url}?secret=UFC&id=${selectedTheme.id}`,

export const userSlice = createSlice({
  name: "pagebuilder",
  initialState: initialState,
  reducers: {
    setPreview: (state, action) => {
      state.preview = action.payload;
    },
    setPageBuilderOffCanvas: (state, { payload }) => {
      state.pageBuilderOffCanvas = payload;
    },
    setPageBuilderItems: (state, action) => {
      state.pageBuilderItems = action.payload;
    },
    setThemes: (state, action) => {
      state.themes = action.payload;
    },
    setSelectedTheme: (state, action) => {
      state.selectedTheme = action.payload;
    },
    editTheme: (state, action) => {
      const { theme, index } = action.payload;
      state.themes[index] = theme;
    },
    set_i_frame: (state, action) => {
      state.i_frame = action.payload;
    },
    replaceComponent: (state, action) => {
      const { component, index } = action.payload;
      state.pageBuilderItems[index] = component;
    },
    replaceData: (state, action) => {
      const { pageBuilderItemIndex, data } = action.payload;
      state.pageBuilderItems[pageBuilderItemIndex].data = data;
    },
    setSelectedLanguageCode: (state, action) => {
      state.selectedLanguageCode = action.payload;
    },
    setLanguages: (state, { payload }) => {
      state.languages = payload;
    },
    addNewPageBuilderItemData: (state, { payload }) => {
      //payload: pageBuilderItemIndex
      const pageBuilderItemIndex = payload;
      //componenet typelarına göre datanın veri tipi
      const pageBuilderItemDataVariables = {
        heroslider: {
          title: null,
          url: null,
          slug: null,
          image: {
            web: null,
            mobile: null,
            tablet: null,
          },
        },
        bannerslider: {
          title: null,
          url: null,
          slug: null,
          image: {
            web: null,
            mobile: null,
            tablet: null,
          },
        },
        bannerblockgrid: {
          type: null,
          title: null,
          url: null,
          slug: null,
          image: {
            web: null,
            mobile: null,
            tablet: null,
          },
        },
        productslider: {
          name: null,
          url: null,
          slug: null,
          price: null,
          sale_price: null,
          image: null,
          description: null,
        },
        commentslider: {
          name: null,
          rating: null,
          text: null,
        },
        blogsection: {
          title: null,
          slug: null,
          image: null,
          description: null,
        },
        imagetext: {
          title: null,
          link: null,
          button_text: null,
          text: null,
          text_color: null,
          background_color: null,
          button_text_color: null,
          button_background_color: null,
          direction: null,
          image: {
            mobile: null,
            tablet: null,
            web: null,
          },
        },
        categoryslider: {
          title: null,
          slug: null,
          url: null,
          image: {
            mobile: null,
            tablet: null,
            web: null,
          },
        },
        brandslider: {
          title: null,
          slug: null,
          url: null,
          image: {
            mobile: null,
            tablet: null,
            web: null,
          },
        },
        mainmenu: {
          // title: null,
          // year: null,
          url_left: null,
          url_right: null,
          backgroundleft: null,
          backgroundright: null,
          button_left: null,
          button_right: null,
          image: {
            image_left: null,
            image_right: null,
          },
        },
        brandblockgrid: {
          title: null,
          slug: null,
          url: null,
          image: {
            front: null,
            back: null,
          },
        },
        testcomponent: {
          title: null,
          text: null,
          color: null,
          direction: null,
          image: {
            mobile: null,
            tablet: null,
            web: null,
          },
        },
        kayanpano: {
          title: null,
          url: null,
          slug: null,
          image: {
            web: null,
            mobile: null,
            tablet: null,
          },
        },
        heroandbanner: {
          title: null,
          type: "hero",
          url: null,
          slug: null,
          external_link: null,
          image: { web: null, mobile: null, tablet: null },
        },
        information: {
          title: null,
          image: { web: null, mobile: null, tablet: null },
        },
        videogrid: {
          type: `3`,
          title: null,
          url: null,
          embed_code: null,
          video_platform: null,
          image: { web: null, mobile: null, tablet: null },
        },
        certificates: {
          title: null,
          text: null,
          image: { web: null, mobile: null, tablet: null },
        },
      };

      //her dil için data veri tipini oluşturma
      let tempData = {};
      state.languages.forEach((language) => {
        tempData[language.code] = {
          ...pageBuilderItemDataVariables[
            state.pageBuilderItems[pageBuilderItemIndex].type
          ],
        };
      });

      state.pageBuilderItems[pageBuilderItemIndex].data.unshift(tempData);

      //seçilen pagebuilder indisinde yeni veri ekleme
      // state.pageBuilderItems[pageBuilderItemIndex].data.unshift(
      //   pageBuilderItemDataVariables[
      //     state.pageBuilderItems[pageBuilderItemIndex].type
      //   ]
      // );
    },
    deletePageBuilderItem: (state, { payload }) => {
      // console.log(payload)
      // will be fixed in the future

      state.pageBuilderItems.splice(payload.pageBuilderItemIndex, 1);
    },
    savePageBuilderItemData: (state, { payload }) => {
      state.pageBuilderItems[state.selectedPageBuilderItemIndex].data[
        state.selectedPageBuilderItemDataIndex
      ] = payload;

      // state.pageBuilderItems[state.selectedPageBuilderItemIndex].data[
      //   state.selectedPageBuilderItemDataIndex
      // ][state.selectedLanguageCode] = payload

      // console.log(
      //   state.pageBuilderItems[state.selectedPageBuilderItemIndex].data[
      //     state.selectedPageBuilderItemDataIndex
      //   ][state.selectedLanguageCode],
      //   'HEY YO'
      // )
      // console.log(payload, 'PAYLOAD')

      // state.pageBuilderOffCanvas.type = 0
      // state.pageBuilderOffCanvas.visible = false
    },
    editPageBuilderItemData: (
      state,
      { payload: { pageBuilderItemIndex, pageBuilderItemDataIndex } }
    ) => {
      state.selectedPageBuilderItemIndex = pageBuilderItemIndex;
      state.selectedPageBuilderItemDataIndex = pageBuilderItemDataIndex;

      state.pageBuilderOffCanvas.type = 2; //editleme canvası
      state.pageBuilderOffCanvas.visible = true; //görünürlüğü aç
    },
    deletePageBuilderItemData: (
      state,
      { payload: { pageBuilderItemIndex, pageBuilderItemDataIndex } }
    ) => {
      state.pageBuilderItems[pageBuilderItemIndex].data.splice(
        pageBuilderItemDataIndex,
        1
      );
    },
  },
});

export const {
  setPageBuilderItems,
  addNewPageBuilderItemData,
  deletePageBuilderItemData,
  savePageBuilderItemData,
  editPageBuilderItemData,
  deletePageBuilderItem,
  setPageBuilderOffCanvas,
  replaceData,
  replaceComponent,
  setSelectedLanguageCode,
  setLanguages,
  setPreview,
  setThemes,
  editTheme,
  setSelectedTheme,
  set_i_frame,
} = userSlice.actions;

export default userSlice.reducer;
